import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import MissionSection from "../Mission";
import BannerSectionStyle from "../Section/BannerSection/BannerSectionStyle";
import Values from "../Values";

export default function About() {
  pageTitle("About");
  return (
    <>
      <BannerSectionStyle
        bgUrl="/images/about/hero-img.jpg"
        aspectRatio={3}
        subTitleStyle={{ fontSize: "1.2rem" }}
      />

      <section className="section">
        <MissionSection
          imgUrl="/images/about/mission.jpg"
          title="<span className='gradient-color'>Our Mission</span>"
          description="Our mission is to increase the operational and financial efficiency of healthcare systems that would lead to the delivery of high quality care to patients. Our solutions are achieving this by enabling intelligent digital care that empowers healthcare personnel, improves patient compliance and experiences, obtains better outcomes, and eliminates burnout."
        />

        <Values />
      </section>
    </>
  );
}

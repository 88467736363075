import parse from "html-react-parser";
import React from "react";
import BannerStrip from "../BannerStrip";
import HomeHeroVideo from "../HomeHeroVideo";

export default function Hero({
  title,
  subTitle,
  subTitle2,
  bgUrl = "",
  aspectRatio = 1,
}) {
  return (
    <section className="cs_hero cs_style_1">
      <div
        className="cs_hero_wrap cs_bg_filed home_bg"
        style={{
          backgroundImage: `url(${bgUrl})`,
          height: `calc(100vw/${aspectRatio})`,
        }}
      >
        <HomeHeroVideo videoUrl="/images/home_1/hero-video.mp4" />
        <div className="container">
          <div className="cs_hero_text">
            <h2 className="cs_hero_title cs_fs_3rem cs_fw_600">
              {parse(title)}
            </h2>
            {subTitle && (
              <p className="cs_hero_subtitle cs_fs_2rem cs_heading_color">
                {parse(subTitle)}
              </p>
            )}
            <p class="cs_hero_subtitle_2">{subTitle2}</p>
          </div>
        </div>
      </div>
      <BannerStrip title="Empower Your Team with Digital Care to Reduce Readmissions" />
    </section>
  );
}

import "./styles.scss";

const DemoIcon = ({ color = "#c90c29", size = "25" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M580 4979 c-214 -23 -412 -164 -509 -360 -75 -153 -72 -72 -69 -1562
l3 -1332 28 -80 c62 -176 173 -302 338 -385 130 -66 88 -63 1025 -70 l851 -5
-121 -265 -121 -265 -180 -5 c-205 -6 -233 -15 -299 -92 -51 -59 -66 -119 -66
-259 0 -100 3 -120 19 -140 l19 -24 1062 0 1062 0 19 24 c16 20 19 40 19 140
0 140 -15 200 -66 259 -66 77 -94 86 -299 92 l-180 5 -121 265 -121 265 851 5
c940 7 894 3 1030 72 163 82 280 222 343 408 17 52 18 125 21 1387 3 1491 6
1409 -69 1563 -57 116 -180 241 -289 293 -164 78 37 72 -2150 73 -1075 1
-1989 -2 -2030 -7z m4041 -136 c132 -45 252 -146 314 -264 68 -129 65 -69 65
-1366 l0 -1173 -2440 0 -2440 0 0 1173 c0 1297 -3 1237 65 1366 81 153 238
261 410 281 33 4 938 7 2010 6 l1950 -1 66 -22z m377 -3010 c-15 -262 -200
-473 -448 -512 -88 -15 -3889 -15 -3980 -1 -144 23 -305 134 -378 262 -40 70
-72 191 -72 275 l0 63 2441 0 2442 0 -5 -87z m-2138 -908 c66 -143 119 -263
120 -267 0 -5 -189 -8 -420 -8 -231 0 -420 3 -420 6 0 3 46 106 101 228 56
121 110 240 121 264 l19 42 180 -2 180 -3 119 -260z m599 -413 c62 -31 76 -61
76 -165 l0 -92 -975 0 -975 0 0 92 c0 104 14 134 76 165 32 17 95 18 899 18
804 0 867 -1 899 -18z"
        />
        <path
          d="M715 1792 c-77 -37 -115 -96 -115 -178 0 -103 81 -184 185 -184 60 0
103 19 142 63 59 67 67 151 21 225 -47 77 -155 111 -233 74z m120 -127 c30
-30 31 -54 4 -89 -24 -31 -72 -35 -101 -9 -28 25 -24 77 8 102 35 27 59 26 89
-4z"
        />
        <path
          d="M1236 1795 c-104 -37 -153 -150 -111 -252 30 -72 92 -113 171 -113
141 0 233 139 175 265 -22 50 -69 91 -119 105 -48 13 -68 12 -116 -5z m109
-130 c14 -13 25 -35 25 -48 0 -33 -35 -67 -70 -67 -65 0 -94 79 -44 119 35 27
59 26 89 -4z"
        />
        <path
          d="M1747 1795 c-148 -52 -170 -253 -39 -336 117 -74 274 0 290 136 10
89 -51 181 -135 204 -49 14 -69 13 -116 -4z m108 -130 c46 -45 19 -115 -45
-115 -65 0 -94 79 -44 119 35 27 59 26 89 -4z"
        />
      </g>
    </svg>
  );
};

export default DemoIcon;

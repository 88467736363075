import BannerSectionStyle from "../Section/BannerSection/BannerSectionStyle";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import TeamMemberCard from "../TeamMemberCard";

const Team = () => {
  return (
    <>
      <BannerSectionStyle
        bgUrl="images/team/team-hero-img.jpg"
        title="Crafting the Future Together"
      />

      <section className="section">
        <section className="team-page container">
          <SectionHeading
            title="<span className='gradient-color'>Leadership</span>"
            fontsize="56"
          />
          <section className="member-section leadership">
            <TeamMemberCard
              imgUrl="/images/team/Jamal.jpg"
              name="Jamal Afridi, PhD"
              designation="Chief Executive Officer,<br/>Co-Founder"
              cardSize="md"
            />

            <TeamMemberCard
              imgUrl="/images/team/Shahzad.jpg"
              name="Muhammad Shahzad, PhD"
              designation="Chief Technical Officer,<br/>Co-Founder"
              cardSize="md"
            />
          </section>
          <Spacing md="100" />
          <SectionHeading
            title="<span className='gradient-color'>Advisors</span>"
            fontsize="50"
          />
          <section className="member-section advisors">
            <TeamMemberCard
              imgUrl="/images/team/Owais.jpg"
              name="Owais Rafique"
              designation="Assistant Vice President<br/>West Virginia University Medicine"
              cardSize="md"
            />
            <TeamMemberCard
              imgUrl="/images/team/Ali.jpg"
              name="Ali Khayam"
              designation="Ex GM @ Amazon Web Services (AWS)<br/>Ex Director Engineering @ VMWare"
              cardSize="md"
            />
          </section>
          <Spacing md="100" />
          <SectionHeading
            title="<span className='gradient-color'>Clinical Team</span>"
            fontsize="50"
          />
          <section className="member-section clinical-team">
            <TeamMemberCard
              imgUrl="/images/team/Sadia.jpg"
              cardSize="sm"
              name="Dr. Sadia Ejaz"
              description="Endocrinologist<br/>Raleigh Endocrine Associates"
              isClTeamMember
            />
            <TeamMemberCard
              imgUrl="/images/team/Naveen.jpg"
              cardSize="sm"
              name="Dr. Naveen Sheshadri"
              description="Cardiologist<br/>UNC Health"
              isClTeamMember
            />
            <TeamMemberCard
              imgUrl="/images/team/Ahmad.jpg"
              cardSize="sm"
              name="Dr. Ahmad Farooq"
              description="Gastroenterologist<br/>Houston Methodist"
              isClTeamMember
            />
            <TeamMemberCard
              imgUrl="/images/team/Amna.jpg"
              cardSize="sm"
              name="Dr. Amna Shabbir"
              description="Geriatrician<br/>Founder & CEO<br/>Early Care Physician Institute"
              isClTeamMember
            />
            <TeamMemberCard
              imgUrl="/images/team/Bhairavi.jpg"
              cardSize="sm"
              name="Dr. Bhairavi Sheshadri"
              description="Endocrinologist<br/>Raleigh Endocrine Associates"
              isClTeamMember
            />
            <TeamMemberCard
              imgUrl="/images/team/Mohsin.jpg"
              cardSize="sm"
              name="Dr. Mohsin Reza"
              description="Family Medicine"
              isClTeamMember
            />

            <TeamMemberCard
              imgUrl="/images/team/Asad.jpg"
              cardSize="sm"
              name="Dr. Asad Ur Rahman"
              description="Medical Director IBD Center<br/>Associate Program Director<br/>Cleveland Clinic Florida"
              isClTeamMember
            />
            <TeamMemberCard
              imgUrl="/images/team/profile_pic_placeholder.jpg"
              cardSize="sm"
              name="Rosa Estrada RN, BSN"
              description="Care Coordinator"
              isClTeamMember
            />
          </section>
        </section>
      </section>
    </>
  );
};

export default Team;

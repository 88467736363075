import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_ACCESS_KEY } from "../../../constants";
import EmailSentStrip from "../../EmailSentStrip";
import Spinner from "../../Spinner";

const defaultValues = {
  firstname: "",
  lastname: "",
  institute: "",
  phone: "",
  email: "",
  linkedin: "",
};

const DemoRequestForm = () => {
  const form = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });
  const [emailSendStatus, setEmailSendStatus] = useState("");

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("access_key", EMAIL_ACCESS_KEY);

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    setEmailSendStatus("sending");
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      setEmailSendStatus("sent");
      reset(); // Reset the form to its default values
    } else {
      setEmailSendStatus("error");
    }
  };

  return (
    <div className="demo_request_form_cont">
      {emailSendStatus === "sending" && <Spinner />}
      {emailSendStatus === "sent" && <EmailSentStrip />}
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            First Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("firstname", { required: "First name is required" })}
            placeholder="Enter your first name"
          />
          {errors.firstname && (
            <span className="cs_error_color">{errors.firstname.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Last Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("lastname", { required: "Last name is required" })}
            placeholder="Enter your last name"
          />
          {errors.lastname && (
            <span className="cs_error_color">{errors.lastname.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Institute Name <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("institute", {
              required: "Institute name is required",
            })}
            placeholder="Enter your institute name"
          />
          {errors.institute && (
            <span className="cs_error_color">{errors.institute.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">Phone</label>
          <input
            className="cs_form_field"
            {...register("phone", {
              pattern: {
                value: /^\d{10}$/,
                message: "Phone number must be in the format xxxxxxxxxx",
              },
            })}
            placeholder="Enter your phone number e.g. 1234567890"
          />
          {errors.phone && (
            <span className="cs_error_color">{errors.phone.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            Email <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Please enter a valid email",
              },
            })}
            placeholder="Enter your email e.g. xyz@abc.com"
          />
          {errors.email && (
            <span className="cs_error_color">{errors.email.message}</span>
          )}
        </div>

        <div className="custom_input">
          <label className="cs_input_label cs_heading_color">
            LinkedIn <span className="cs_accent_color">*</span>
          </label>
          <input
            className="cs_form_field"
            {...register("linkedin", {
              required: "LinkedIn profile link is required",
              pattern: {
                value: /^https?:\/\/(www\.)?linkedin\.com\/.*$/,
                message: "Please enter a valid LinkedIn URL",
              },
            })}
            placeholder="Enter your LinkedIn profile link e.g. linkedin.com/in/username"
          />
          {errors.linkedin && (
            <span className="cs_error_color">{errors.linkedin.message}</span>
          )}
        </div>
        <button type="submit" className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img src="images/icons/arrow_white.svg" alt="Icon" />
            <img src="images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </form>
    </div>
  );
};

export default DemoRequestForm;

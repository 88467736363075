import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_ACCESS_KEY } from "../../constants";
import EmailSentStrip from "../EmailSentStrip";
import Spinner from "../Spinner";

export default function ContactForm() {
  const [emailSendStatus, setEmailSendStatus] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    const formData = new FormData();
    formData.append("access_key", EMAIL_ACCESS_KEY);

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      setEmailSendStatus("sending");
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setEmailSendStatus("sent");
        reset();
      } else {
        setEmailSendStatus("error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      {emailSendStatus === "sending" && <Spinner />}
      {emailSendStatus === "sent" && <EmailSentStrip />}
      <form className="row" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            {...register("name", { required: "Name is required" })}
            type="text"
            className="cs_form_field"
            placeholder="David John"
          />
          {errors.name && (
            <span className="cs_error_color">{errors.name.message}</span>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Enter a valid email address",
              },
            })}
            type="email"
            className="cs_form_field"
            placeholder="example@gmail.com"
          />
          {errors.email && (
            <span className="cs_error_color">{errors.email.message}</span>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Subject</label>
          <input
            {...register("subject", { required: "Subject is required" })}
            type="text"
            className="cs_form_field"
            placeholder="Your subject"
          />
          {errors.subject && (
            <span className="cs_error_color">{errors.subject.message}</span>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            {...register("message", { required: "Message is required" })}
            cols={30}
            rows={10}
            className="cs_form_field"
            placeholder="Write something..."
          />
          {errors.message && (
            <span className="cs_error_color">{errors.message.message}</span>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button type="submit" className="cs_btn cs_style_1">
            <span>Submit</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </form>
    </div>
  );
}

import "./styles.scss";

const HomeIcon = ({ color = "primary", size = "25" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={`var(--${color})`}
        stroke="none"
      >
        <path
          d="M2491 4819 c-19 -12 -579 -419 -1244 -906 -780 -572 -1216 -897
 -1228 -917 -65 -106 61 -240 171 -183 14 7 549 397 1190 867 641 469 1172 854
 1180 854 8 0 546 -389 1194 -864 1104 -808 1182 -863 1225 -867 108 -9 179
 108 119 197 -14 21 -470 361 -1203 898 -649 475 -1207 882 -1239 903 -69 45
 -113 50 -165 18z"
        />
        <path
          d="M626 2770 c-65 -42 -61 36 -64 -1190 -2 -613 0 -1139 3 -1169 5 -41
 14 -61 40 -90 l33 -36 711 -3 c786 -3 751 -5 791 61 19 31 20 55 20 709 0 463
 4 694 11 730 44 208 249 347 455 309 164 -30 289 -149 323 -309 7 -36 11 -267
 11 -730 0 -654 1 -678 20 -709 40 -66 5 -64 791 -61 l711 3 33 36 c26 29 35
 49 40 90 3 30 5 556 3 1169 -3 1044 -4 1117 -21 1147 -48 89 -185 84 -232 -7
 -13 -25 -15 -175 -15 -1105 l0 -1075 -529 0 -530 0 -4 628 c-4 700 -4 693 -75
 831 -90 173 -252 303 -433 346 -85 20 -243 19 -325 -1 -179 -45 -353 -189
 -433 -357 -65 -138 -64 -119 -68 -815 l-4 -632 -530 0 -529 0 0 1075 c0 1173
 3 1107 -56 1154 -35 28 -108 28 -148 1z"
        />
      </g>
    </svg>
  );
};

export default HomeIcon;

import React, { useEffect, useRef, useState } from "react";
import MuteIcon from "../../Icons/MuteIcon";
import VolumeIcon from "../../Icons/Volume";

import "./styles.scss";

const HomeHeroVideo = ({ videoUrl }) => {
  const videoRef = useRef(null);

  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      if (currentTime >= duration - 0.2 && !videoRef.current.muted) {
        videoRef.current.muted = true;
        setIsMuted(true);
      }
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.removeAttribute("controls");
    }
  }, []);

  return (
    <div className="home-hero-video-cont">
      <video
        ref={videoRef}
        src={videoUrl}
        className="home-hero-video"
        id="home-hero-video"
        autoPlay
        loop
        muted
        playsInline
        onTimeUpdate={handleTimeUpdate}
      />
      <button onClick={toggleMute} className="mute-button gradient-color">
        {isMuted ? (
          <MuteIcon color="#c90c29" />
        ) : (
          <VolumeIcon color="#c90c29" />
        )}
      </button>
    </div>
  );
};

export default HomeHeroVideo;

import "./styles.scss";

const TeamIcon = ({ color = "$primary", size = "25" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={`var(--${color})`}
        stroke="none"
      >
        <path
          d="M2402 3860 c-241 -63 -430 -254 -492 -496 -54 -212 12 -451 169 -614
 l66 -68 -74 -26 -75 -25 -58 55 c-36 33 -93 71 -147 97 l-88 43 38 44 c177
 205 170 495 -15 695 -126 136 -330 197 -505 150 -180 -47 -340 -210 -380 -385
 -13 -58 -13 -182 0 -240 15 -67 64 -160 114 -217 l44 -50 -57 -23 c-183 -75
 -327 -245 -367 -437 -18 -83 -21 -404 -4 -435 30 -56 433 -198 563 -198 44 0
 76 35 76 82 0 50 -22 65 -131 87 -118 25 -215 55 -298 92 l-63 29 4 157 c4
 133 8 167 27 215 49 125 149 223 276 270 54 20 84 22 283 26 143 2 247 0 290
 -8 76 -13 171 -57 220 -101 l33 -30 -74 -73 c-137 -136 -217 -300 -237 -492
 -6 -54 -10 -181 -8 -281 4 -215 -6 -197 143 -265 569 -262 1201 -263 1765 -3
 155 72 144 53 148 268 2 100 -2 227 -8 281 -20 192 -100 356 -238 493 l-75 74
 34 29 c52 45 147 87 221 100 43 8 147 10 290 8 199 -4 229 -6 283 -26 127 -47
 227 -145 276 -270 19 -48 23 -82 27 -214 l4 -157 -38 -19 c-68 -35 -206 -79
 -313 -101 -119 -24 -141 -38 -141 -91 0 -47 33 -80 81 -80 47 1 189 33 285 65
 114 38 260 110 273 133 17 31 14 352 -4 436 -40 189 -185 362 -367 436 l-57
 23 44 50 c82 94 125 209 125 337 -1 150 -45 256 -154 364 -109 109 -220 156
 -371 156 -278 -1 -515 -240 -515 -520 0 -121 47 -245 129 -340 l38 -44 -88
 -43 c-54 -26 -111 -64 -147 -97 l-58 -55 -77 26 -77 27 35 28 c89 74 183 236
 210 363 62 293 -89 599 -363 735 -139 69 -306 87 -450 50z m260 -151 c189 -39
 358 -208 397 -397 35 -169 -15 -330 -143 -458 -207 -207 -505 -207 -712 0
 -207 207 -207 506 0 712 129 128 289 178 458 143z m-1169 -167 c65 -29 151
 -113 184 -181 25 -49 28 -68 28 -151 0 -85 -3 -101 -29 -153 -35 -68 -118
 -150 -183 -180 -39 -18 -66 -22 -143 -22 -84 0 -102 3 -152 28 -211 103 -274
 365 -133 550 36 47 106 97 171 122 57 22 195 15 257 -13z m2429 -5 c76 -37
 143 -104 176 -175 23 -49 27 -70 27 -152 0 -84 -3 -102 -28 -152 -36 -74 -101
 -139 -175 -175 -50 -25 -68 -28 -152 -28 -77 0 -104 4 -143 22 -66 31 -152
 115 -184 182 -25 49 -28 67 -28 151 1 114 20 164 91 240 86 92 155 121 279
 117 64 -2 91 -7 137 -30z m-997 -1012 c228 -48 423 -234 480 -460 22 -88 33
 -446 14 -461 -29 -23 -219 -97 -329 -129 -188 -54 -308 -70 -530 -70 -222 0
 -342 16 -530 70 -110 32 -300 106 -329 129 -19 15 -8 373 14 461 57 223 251
 411 475 459 95 21 638 21 735 1z"
        />
      </g>
    </svg>
  );
};

export default TeamIcon;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../../Icons/HomeIcon";
import TeamIcon from "../../Icons/TeamIcon";
import DemoIcon from "../../Icons/DemoIcon";
import AboutIcon from "../../Icons/AboutIcon";
import ContactIcon from "../../Icons/ContactIcon";

const PAGES = {
  HOME: "home",
  TEAM: "team",
  DEMO: "demo",
  ABOUT: "about",
  CONTACT: "contact",
};

export default function Header({ logoSrc, variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState("home");
  const navbarRef = useRef(null);
  const isSmallScreen = window.innerWidth < 1200;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navItemClickHanlder = (itemName) => {
    setCurrentPage(itemName);
    setMobileToggle(!mobileToggle);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setMobileToggle(false);
    }
  };

  return (
    <>
      <header
        className={`cs_site_header cs_style1 cs_sticky_header ${
          mobileToggle ? "cs_mobile_toggle_active" : ""
        } ${variant} ${isSticky ? "cs_active_sticky" : ""}`}
        ref={navbarRef}
      >
        <div
          className={`cs_main_header ${
            isSmallScreen ? "small-screen-nav" : ""
          }`}
        >
          <div className="cs_main_header_in">
            <Link className="cs_site_branding" to="/">
              <img src={logoSrc} alt="Logo" />
            </Link>
            <div className="cs_main_header_left">
              <nav className="cs_nav">
                <ul
                  className={`${
                    mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                  }`}
                >
                  <li className="menu-item-has-children">
                    <Link
                      to="/"
                      onClick={() => navItemClickHanlder(PAGES.HOME)}
                      className={currentPage === PAGES.HOME && "selected"}
                    >
                      {isSmallScreen && (
                        <HomeIcon
                          color={currentPage === PAGES.HOME && "accent"}
                        />
                      )}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      onClick={() => navItemClickHanlder(PAGES.TEAM)}
                      className={currentPage === PAGES.TEAM && "selected"}
                    >
                      {isSmallScreen && (
                        <TeamIcon
                          color={currentPage === PAGES.TEAM && "accent"}
                        />
                      )}
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/demo"
                      onClick={() => navItemClickHanlder(PAGES.DEMO)}
                      className={currentPage === PAGES.DEMO && "selected"}
                    >
                      {isSmallScreen && (
                        <DemoIcon
                          color={currentPage === PAGES.DEMO && "accent"}
                        />
                      )}
                      Demo
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      onClick={() => navItemClickHanlder(PAGES.ABOUT)}
                      className={currentPage === PAGES.ABOUT && "selected"}
                    >
                      {isSmallScreen && (
                        <AboutIcon
                          color={currentPage === PAGES.ABOUT && "accent"}
                        />
                      )}
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      onClick={() => navItemClickHanlder(PAGES.CONTACT)}
                      className={currentPage === PAGES.CONTACT && "selected"}
                    >
                      {isSmallScreen && (
                        <ContactIcon
                          color={currentPage === PAGES.CONTACT && "accent"}
                        />
                      )}
                      Contact
                    </Link>
                  </li>
                </ul>
                <span
                  className={
                    mobileToggle
                      ? "cs_menu_toggle cs_teggle_active"
                      : "cs_menu_toggle"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

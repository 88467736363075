import React from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Hero from "../Hero";
import HomeSection1 from "../HomeSection1";
import HomeSection2 from "../HomeSection2";
import HomeSection3 from "../HomeSection3";
import HomeSection4 from "../HomeSection4";
import BrandsSection from "../Section/BrandsSection";

const brandData = [
  {
    imgUrl: "images/partners/raleigh_endocrine_associates_logo.png",
    imgAlt: "Logo of Raleigh Endocrine Associates",
  },
  {
    imgUrl: "images/partners/st_luke_hospital_logo.png",
    imgAlt: "Logo of St. Luke's Hospital",
  },
  { imgUrl: "images/partners/nsf_logo.png", imgAlt: "Logo of NSF" },
  {
    imgUrl: "images/partners/nc_state_logo.png",
    imgAlt: "Logo of North Carolina State University",
  },
  {
    imgUrl: "images/partners/unc_chapel_hill_logo.png",
    imgAlt: "Logo of University of North Carolina at Chapel Hill",
  },
  {
    imgUrl: "images/partners/michigan_state_university_logo.png",
    imgAlt: "Logo of Michigan State University",
  },
  {
    imgUrl: "images/partners/microsoft_logo.png",
    imgAlt: "Logo of Microsoft",
  },
  {
    imgUrl: "images/partners/cisco_logo.png",
    imgAlt: "Logo of Cisco",
  },
  {
    imgUrl: "images/partners/sony_logo.png",
    imgAlt: "Logo of Sony",
  },
  {
    imgUrl: "images/partners/3m_logo.png",
    imgAlt: "Logo of 3M",
  },
  {
    imgUrl: "images/partners/solventum_logo.png",
    imgAlt: "Logo of Solventum",
  },
  {
    imgUrl: "images/partners/technicolor_logo.png",
    imgAlt: "Logo of Technicolor",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="<div className='gradient-color'> Reducing Readmissions,</div>
               <div className='gradient-color title-2'>Empowering Healthcare Teams.</div>"
        showRequestModal
        aspectRatio={1.77}
      />

      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />

      <BrandsSection sectionTitle="Bringing experience from" data={brandData} />
    </>
  );
}

import "./styles.scss";

const AboutIcon = ({ color = "#c90c29", size = "25" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M720 4947 c-49 -16 -133 -102 -148 -153 -8 -27 -12 -117 -12 -256 l0
-215 -82 -5 c-136 -9 -233 -70 -288 -183 -34 -69 -35 -198 -2 -265 34 -71 75
-114 142 -149 52 -28 76 -34 146 -39 l84 -5 0 -157 0 -157 -82 -5 c-136 -9
-233 -70 -288 -183 -34 -69 -35 -198 -2 -265 34 -71 75 -114 142 -149 52 -28
76 -34 146 -39 l84 -5 0 -157 0 -157 -82 -5 c-136 -9 -233 -70 -288 -183 -34
-69 -35 -198 -2 -265 34 -71 75 -114 142 -149 52 -28 76 -34 146 -39 l84 -5 0
-157 0 -157 -82 -5 c-136 -9 -233 -70 -288 -183 -34 -69 -35 -198 -2 -265 34
-71 75 -114 142 -149 52 -28 76 -34 146 -39 l84 -5 0 -215 c0 -139 4 -229 12
-256 17 -58 100 -138 158 -154 35 -10 474 -12 1986 -10 l1940 3 76 37 c91 45
147 103 191 196 l32 67 0 2095 0 2095 -32 67 c-44 93 -100 151 -191 196 l-76
37 -1950 2 c-1405 1 -1961 -2 -1986 -10z m560 -2387 l0 -2240 -255 0 -256 0
-24 25 c-25 24 -25 24 -25 238 l0 214 84 5 c70 5 94 11 145 38 69 37 100 69
139 144 22 43 26 64 26 136 1 78 -2 90 -33 148 -60 110 -144 161 -278 170
l-83 5 0 157 0 157 83 5 c133 9 219 61 278 170 31 58 34 70 34 148 0 78 -3 90
-34 148 -60 110 -144 161 -278 170 l-83 5 0 157 0 157 84 5 c70 5 94 11 145
38 69 37 100 69 139 144 22 43 26 64 26 136 1 78 -2 90 -33 148 -60 110 -144
161 -278 170 l-83 5 0 157 0 157 83 5 c133 9 219 61 278 170 31 58 34 70 34
148 0 78 -3 90 -34 148 -60 110 -144 161 -278 170 l-83 5 0 214 c0 214 0 214
25 238 l24 25 256 0 255 0 0 -2240z m3034 2228 c55 -16 138 -99 154 -154 18
-60 18 -4088 0 -4148 -16 -55 -99 -138 -154 -154 -32 -9 -375 -12 -1458 -12
l-1416 0 0 2240 0 2240 1416 0 c1083 0 1426 -3 1458 -12z m320 0 c26 -8 60
-31 91 -63 32 -31 55 -65 63 -91 18 -60 18 -4088 0 -4148 -16 -55 -99 -138
-154 -154 -72 -21 -74 -19 -35 60 l36 72 0 2096 0 2096 -36 72 c-39 79 -37 81
35 60z m-3748 -654 c53 -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -25 -19
-44 -21 -224 -24 -223 -4 -256 2 -301 53 -52 59 -54 147 -4 206 47 55 67 59
295 56 191 -2 209 -4 234 -23z m0 -960 c53 -39 69 -71 69 -134 0 -63 -16 -95
-69 -134 -25 -19 -44 -21 -224 -24 -223 -4 -256 2 -301 53 -52 59 -54 147 -4
206 47 55 67 59 295 56 191 -2 209 -4 234 -23z m0 -960 c53 -39 69 -71 69
-134 0 -63 -16 -95 -69 -134 -25 -19 -44 -21 -224 -24 -223 -4 -256 2 -301 53
-52 59 -54 147 -4 206 47 55 67 59 295 56 191 -2 209 -4 234 -23z m0 -960 c53
-39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -25 -19 -44 -21 -224 -24 -223 -4
-256 2 -301 53 -52 59 -54 147 -4 206 47 55 67 59 295 56 191 -2 209 -4 234
-23z"
        />
        <path
          d="M2825 3754 c-169 -26 -296 -67 -431 -138 -311 -166 -534 -463 -610
-816 -26 -118 -26 -362 0 -480 104 -482 474 -845 961 -941 40 -8 132 -14 215
-14 83 0 175 6 215 14 488 96 849 450 962 943 23 101 23 373 0 476 -110 483
-461 834 -939 938 -71 15 -312 27 -373 18z m395 -187 c637 -167 962 -875 670
-1462 -27 -55 -68 -125 -90 -155 l-40 -55 0 42 c0 58 -24 177 -49 244 -62 163
-214 323 -378 395 l-55 25 40 43 c171 185 158 483 -27 659 -268 256 -702 131
-796 -230 -38 -147 4 -317 107 -429 l40 -43 -57 -26 c-161 -71 -315 -233 -376
-394 -25 -67 -49 -186 -49 -244 l0 -42 -40 55 c-345 478 -226 1149 262 1473
127 85 247 133 401 163 111 21 318 12 437 -19z m-125 -318 c237 -112 246 -441
15 -568 -60 -33 -71 -36 -150 -36 -78 0 -91 3 -148 34 -249 135 -220 488 48
585 63 23 170 16 235 -15z m126 -801 c94 -35 148 -69 213 -134 65 -66 99 -119
134 -213 21 -56 26 -89 30 -211 l4 -146 -36 -28 c-20 -15 -74 -48 -120 -72
-309 -164 -663 -164 -972 0 -46 24 -99 57 -119 71 l-35 27 0 112 c0 140 13
212 54 301 57 122 155 218 282 276 90 41 141 48 329 45 153 -2 177 -5 236 -28z"
        />
      </g>
    </svg>
  );
};

export default AboutIcon;

import CustomDivider from "../CustomDivider";
import ProductFeatures from "../Product/ProductFeatures";
import SectionHeading from "../SectionHeading";

const productFeatures = [
  {
    imgUrl: "/images/home_1/multi-lingual.png",
    title:
      "Customizable care assistants with multilingual support (English, Spanish, Arabic, and many more)",
  },
  {
    imgUrl: "/images/home_1/availability.png",
    title: "On-demand availability for need based care",
  },
  {
    imgUrl: "/images/home_1/compliance.png",
    title: "Patient education, encouragement, and compliance",
  },
  {
    imgUrl: "/images/home_1/staff-burnout.jpg",
    title: "Preemptive readmission prevention",
  },
  {
    imgUrl: "/images/home_1/ehr-integration.jpg",
    title: "Secure and seamless integration with hospital EHRs",
  },
];
const HomeSection4 = () => {
  return (
    <section className="section">
      <div className="container">
        <CustomDivider
          title={
            <SectionHeading
              title="<span className='gradient-color'>Why is our AI-driven follow-up care so effective?</span>"
              center
              fontsize="3rem"
              style={{ marginBottom: "0px" }}
            />
          }
        />
      </div>
      <ProductFeatures
        productFeatures={productFeatures}
        slides={5}
        titleStyle={{
          margin: "20px 20px 0px",
          fontSize: "1rem",
          lineHeight: "1.4rem",
        }}
      />
    </section>
  );
};

export default HomeSection4;
